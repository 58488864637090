










import Vue from 'vue';
import { EQuestionType, IAssessmentQuestion } from '@/scripts/store/modules/assessments/types';
import { TherapyTypeEnum, IStyleContent, ITherapyChoice, ITherapyContentWithChoices, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import ElementMcq from '@/views/products/components/elements/element-mcq.vue';
import ElementMsq from '@/views/products/components/elements/element-msq.vue';
import ElementSlider from '@/views/products/components/elements/element-slider.vue';
import ElementStyledContent from '@/views/products/components/element-styled-content.vue';

export default Vue.extend({
  name: 'assessment-question',
  components: {
    ElementMcq,
    ElementMsq,
    ElementSlider,
    ElementStyledContent,
  },
  props: {
    question: {
      type: Object as () => IAssessmentQuestion,
      required: true,
    },
  },
  data() {
    return {
      therapyContent: null as ITherapyContentWithChoices | null,
      questionTypes: EQuestionType,
    };
  },
  computed: {
    questionContent(): Array<IStyleContent> {
      let style: Array<IStyleContent> = [];

      if (this.question.title) {
        style.push({ key: StyleTypeEnum.H4, content: this.question.title });
      }

      if (this.question.styleContent) {
        style = style.concat(this.question.styleContent);
      }

      return style;
    },
    therapyType(): TherapyTypeEnum | null {
      switch (this.question.type) {
        case EQuestionType.MULTISELECT:
          return TherapyTypeEnum.MSQ;
        case EQuestionType.SINGLESELECT:
          return TherapyTypeEnum.MCQ;
        case EQuestionType.SLIDER:
          return TherapyTypeEnum.SLIDER;
        default:
          return null;
      }
    },
    isOnLastPosition(): boolean {
      if (!this.therapyContent?.styleContent?.length) return false;
      const lastPart = this.therapyContent.styleContent[this.therapyContent.styleContent.length - 1];
      return lastPart.key === StyleTypeEnum.CONTENT || lastPart.key === StyleTypeEnum.PLACEHOLDER;
    },
  },
  mounted() {
    this.therapyContent = {
      uen: this.question.id,
      type: this.therapyType,
      allowComments: false,
      allowSkip: false,
      allowBack: false,
      answered: false,
      choices: this.question.choices.map(choice => {
        return {
          id: parseInt(choice.id),
          selected: choice.selected,
          content: choice.title,
          styleContent: [],
        } as ITherapyChoice;
      }),
      styleContent: this.question.styleContent,
    } as ITherapyContentWithChoices;
  },
  methods: {
    selectChoice(selection: { valid: boolean, feedback: Array<IStyleContent>, oldSelection: boolean }) {
      if (!this.therapyContent) return;
      const therapyChoice = this.therapyContent.choices.filter(opt => opt.selected);
      const choices = this.question.choices.filter(opt => therapyChoice.some(tc => opt.id === tc.id.toString()));
      this.$emit('selected', { choices, oldSelection: selection.oldSelection });
    },
  },
});
