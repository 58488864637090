var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "element-assessment pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _vm.firstLoad
            ? _c(
                "v-flex",
                { attrs: { xs12: "", "text-center": "" } },
                [
                  _c("v-progress-circular", {
                    attrs: { size: 100, color: "primary", indeterminate: "" }
                  })
                ],
                1
              )
            : _vm.loadedAssessment
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _vm.loadedAssessment.title
                        ? _c("v-flex", { attrs: { xs12: "" } }, [
                            _c(
                              "h2",
                              {
                                staticClass: "starling-h2",
                                class: {
                                  "pb-3": !_vm.loadedAssessment.subtitle
                                }
                              },
                              [_vm._v(_vm._s(_vm.loadedAssessment.title))]
                            )
                          ])
                        : _vm._e(),
                      _vm.loadedAssessment.subtitle
                        ? _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("h4", { staticClass: "starling-h4 pb-3" }, [
                              _vm._v(_vm._s(_vm.loadedAssessment.subtitle))
                            ])
                          ])
                        : _vm._e(),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-scale-transition",
                            {
                              staticClass: "layout row wrap",
                              attrs: {
                                group: "",
                                "leave-absolute": "",
                                origin: "center center 0",
                                tag: "div"
                              }
                            },
                            [
                              _vm._l(_vm.questions, function(question, index) {
                                return _c("assessment-question", {
                                  key: question.id,
                                  class: "question-" + index,
                                  attrs: { question: question },
                                  on: {
                                    selected: function($event) {
                                      return _vm.onSelectChoice(
                                        question,
                                        $event
                                      )
                                    }
                                  }
                                })
                              }),
                              _vm.loadedAssessment.footer
                                ? _c(
                                    "v-flex",
                                    { key: "footer", attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "starling-citation pb-3"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.loadedAssessment.footer)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.isAssessmentComplete
                                ? _c(
                                    "v-flex",
                                    {
                                      key: "instructions",
                                      attrs: { xs12: "", "pb-3": "" }
                                    },
                                    [
                                      _c("element-part-instructional", {
                                        attrs: { part: _vm.instructions }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm.isAssessmentComplete &&
                      _vm.loadedAssessment.notesEnabled
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "", "mb-3": "" } },
                            [
                              _c("input-note-wrapper-assessment", {
                                attrs: {
                                  note: _vm.loadedAssessment.notes,
                                  loading: _vm.loading,
                                  type: _vm.loadedAssessment.name,
                                  isAssessmentNote: "",
                                  color: "primary"
                                },
                                on: { updated: _vm.onNotesChanged }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }